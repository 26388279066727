
const adminItems = [
    {
        label: 'Usuarios',
        icon: 'pi pi-home',
        command: () => {
            window.location.href = "/dashboard/usuarios";
        }
    },
]

const leaderItems = [
    {
        label: 'Torneos',
        icon: 'pi pi-calendar',
        command: () => {
            window.location.href = "/dashboard/torneos";
        }
    },
    {
        label: 'Mis Deportistas',
        icon: 'pi pi-users',
        command: () => {
            window.location.href = "/dashboard/deportistas";
        }
    }
];

const organizerItems = [
    {
        label: 'Torneos',
        icon: 'pi pi-calendar',
        command: () => {
            window.location.href = "/dashboard/torneos";
        }
    },
]

export { adminItems, leaderItems, organizerItems };