import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
        <div className="container">
            <span className="text-muted">zRghtside Dev, Todos los derechos reservados.</span>
        </div>
        </footer>
    );
    }


export default Footer;