import { Outlet } from "react-router-dom";
import Navbar from "../navbar/Navbar.js";
import { useContext } from "react";
import { loginContext } from "../../index.js";
import Footer from "../footer/Footer.js";
import "./Layout.css";

const Layout = ({ children, centerNavbar }) => {
    const context = useContext(loginContext);
    if(!context.requestCompleted) return;
    console.log(context);
    return (
        <div className="layout">
            <Navbar context={context} centerNavbar={centerNavbar}/>
            <div className="layout-content">
                {children}
            </div>
            <Footer/>
            <Outlet/>
        </div>
    );
}

export default Layout;