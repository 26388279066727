import LoginWrapper from "../../../../utils/authentication/LoginWrapper";
import Layout from "../../../dashboard/Layout";
import React, { createRef, useContext, useEffect, useState } from 'react';
import './Torneos.css';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { loginContext, toastContext } from "../../../../index.js";
import 'primeicons/primeicons.css';
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { getPaises, getDepartamentos, getCiudades, getTipoDeportes, getUsuarios, addTorneo, getTorneos, deleteTorneo, editTorneo, inscribirseTorneo } from "../../../../utils/http_requests/index.js";
import { InputTextarea } from "primereact/inputtextarea";
import ReCAPTCHA from "react-google-recaptcha";
window.Buffer = window.Buffer || require("buffer").Buffer;

const wait = (time) => new Promise((resolve) => setTimeout(resolve, time));

// create a buffer to url function that can be used for src attributes using Buffer
const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return `data:image/jpeg;base64,${window.btoa(binary)}`;
}


const Torneos = () => {
    const { showToast } = useContext(toastContext);
    const recaptchaRef = createRef();
    const context = useContext(loginContext);


    const [isOrganizer, setIsOrganizer] = useState(false);
    const [visibleAddTournament, setVisibleAddTournament] = useState(false);
    const [deportes, setTipoDeportes] = useState([{ nombre: "Fútbol", id: 1 }, { nombre: "Baloncesto", id: 2 }, { nombre: "Voleibol", id: 3 }]);
    const [dates, setDates] = useState([new Date(), new Date()]);
    const [paises, setPaises] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [currentAction, setCurrentAction] = useState("add");
    const [toEditData, setToEditData] = useState({});
    const [usuario, setSelectedUsuario] = useState(0);
    const [estado_inscripcion, setSelectedEstadoInscripcion] = useState(0);
    const [pais, setSelectedPais] = useState(null);
    const [equipos_usuario, setEquiposUsuario] = useState([]);
    const [selectedDropdownFilter, setSelectedDropdownFilter] = useState(0);
    const [deporte, setSelectedDeporte] = useState(null);
    const [estado, setSelectedEstado] = useState(null);
    const [nombre, setNombre] = useState(null);
    const [currentEditTournament, setCurrentEditTournament] = useState(null);
    const [responsable, setResponsable] = useState(null);
    const [ubicacion, setUbicacion] = useState(null);
    const [torneos, setTorneos] = useState([]);
    const [unmutableTorneos, setUnmutableTorneos] = useState([]);
    const [imagenBase64, setImagenBase64] = useState(null);
    const [descripcion, setDescripcion] = useState(null);
    const [precio, setPrecio] = useState(null);
    const [moneda, setMoneda] = useState("COP");
    const [ciudad, setSelectedCiudad] = useState(null);
    const [departamento, setSelectedDepartamento] = useState(null);
    const [departamentos, setDepartamentos] = useState([]);
    const [ciudades, setCiudades] = useState([]);



    /* Update Form States */
    const resetActionsFormStates = () => {
        setNombre(null);
        setSelectedEstado(null);
        setSelectedEstadoInscripcion(null);
        setDescripcion(null);
        setPrecio(null);
        setMoneda(null);
        setSelectedUsuario(null);
        setSelectedDeporte(null);
        setResponsable(null);
        setUbicacion(null);
        setDates([new Date(), new Date()]);
        setSelectedDeporte(null);
        setImagenBase64(null);
        setVisibleAddTournament(true);
    }

    const updateActionsFormStatesEdit = async (e) => {
        const parent = e.target.closest(".torneo_card");
        console.log(parent);
        const id = parseInt(parent.id.split("-")[1]);
        setCurrentEditTournament(id);
        console.log(id);
        const tournamentData = unmutableTorneos.find(torneo => torneo.id === id);
        console.log(tournamentData);
        setVisibleAddTournament(true);
        setSelectedEstado(tournamentData.estado ? 1 : 0);
        setSelectedEstadoInscripcion(tournamentData.estado_inscripcion ? 1 : 0);
        setMoneda(tournamentData.moneda);
        setSelectedUsuario(tournamentData.id_arbitro);
        setSelectedDeporte(tournamentData.tipo_deporte_id);
        setResponsable(tournamentData.responsable);
        setDates([new Date(tournamentData.fecha_inicio), new Date(tournamentData.fecha_fin)]);
        setSelectedDeporte(tournamentData.tipo_deporte_id);
        await wait(1000);
        const imagePreviewContainer = document.querySelector(".image_preview_container");
        if (imagePreviewContainer) {
            imagePreviewContainer.innerHTML = `<img src="${arrayBufferToBase64(tournamentData.foto.data)}" alt="preview" style="width: 100%; height: 100%; object-fit: contain" />`;
            setImagenBase64(arrayBufferToBase64(tournamentData.foto.data).split(",")[1]);
        }
    }
    /* ----------------  */



    /* Header and Footer Elements for the Dialog */
    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-4">
            <span className="font-bold white-space-nowrap">Torneos</span>
        </div>
    );

    const footerContent = (action) => {
        switch (action) {
            case "add":
                return (
                    <div className="footer_buttons">
                        <Button label="Cancelar" className="p-button-raised p-button-danger" onClick={() => setVisibleAddTournament(false)} />
                        <Button label="Crear" className="p-button-raised p-button-primary" onClick={handleAddTorneo} />
                    </div>
                );
            case "edit":
                return (
                    <div className="footer_buttons">
                        <Button label="Cancelar" className="p-button-raised p-button-danger" onClick={() => setVisibleAddTournament(false)} />
                        <Button label="Editar" className="p-button-raised p-button-primary" onClick={handleEditTorneo} />
                    </div>
                );
            default:
                return (
                    <div className="footer_buttons">
                        <Button label="Cancelar" className="p-button-raised p-button-danger" onClick={() => setVisibleAddTournament(false)} />
                        <Button label="Crear" className="p-button-raised p-button-primary" onClick={handleAddTorneo} />
                    </div>
                );

        }
    };

    /* ----------------  */


    /* CRUD Operations */



    const handleEliminarTorneo = async (e) => {
        // get container's parent
        const parent = e.target.closest(".torneo_card");
        const id = parseInt(parent.id.split("-")[1]);
        const confirm = window.confirm("¿Estás seguro de que deseas eliminar el torneo con ID " + id + "?");
        if (!confirm) return;
        const response = await deleteTorneo(id);

        if (response.data.success) {
            const torneosFiltered = unmutableTorneos.filter(torneo => torneo.id !== id);
            setTorneos(torneosFiltered);
            setUnmutableTorneos(torneosFiltered);
        }

    };

    const handleEditTorneo = async (e) => {
        const currentRecaptchaValue = recaptchaRef.current.getValue();
        const id = currentEditTournament;
        console.log(toEditData);
        // check if toEditData is empty
        if (Object.keys(toEditData).length === 0) {
            showToast({ severity: 'warn', summary: 'Advertencia', detail: 'Por favor edita al menos un campo para poder hacer esto', life: 3000 });
            return;
        }
        if (!currentRecaptchaValue) {
            showToast({ severity: 'warn', summary: 'Advertencia', detail: 'Por favor verifica el captcha', life: 3000 });
            return;
        };
        try {
            const response = await editTorneo(toEditData, id, currentRecaptchaValue);
            if (response.data.success) {
                setVisibleAddTournament(false);
                // update the torneos array with the new torneo
                const torneo = response.data.torneo;
                const index = unmutableTorneos.findIndex(torneo => torneo.id === id);
                unmutableTorneos[index] = torneo;

            }

        } catch (error) {
            // reset the captcha
            recaptchaRef.current.reset();
        };
    }

    const handleAddTorneo = async (e) => {
        const data = {
            nombre,
            descripcion,
            precio_inscripcion: precio,
            moneda,
            responsable,
            gps_ubicacion: ubicacion,
            foto: imagenBase64,
            fecha_inicio: dates[0],
            fecha_fin: dates[1],
            ciudad_id: ciudad,
            tipo_deporte_id: deporte,
            arbitro_id: usuario,
            captcha_token: recaptchaRef.current.getValue()
        }
        for (let key in data) {
            if (!data[key] && data[key] !== 0) {
                console.log(data[key])
                console.log(key);
                showToast({ severity: 'warn', summary: 'Advertencia', detail: 'Por favor llena todos los campos', life: 3000 });
                return;
            }
        }
        try {
            const response = await addTorneo(nombre, descripcion, precio, moneda, responsable, ubicacion, estado, estado_inscripcion, imagenBase64, dates, ciudad, deporte, recaptchaRef.current.getValue(), usuario);
            if (response.data.success) {
                setVisibleAddTournament(false);
                const torneo = response.data.torneo;
                console.log(torneo);
                setUnmutableTorneos([...torneos, torneo]);
                setTorneos([...torneos, torneo]);
            }
        } catch (error) {
            recaptchaRef.current.reset();
        };

    }

    const handleInscribirse = async (e) => {
        const parent = e.target.closest(".torneo_card");
        const id = parseInt(parent.id.split("-")[1]);
        console.log(id);
        const response = await inscribirseTorneo(id);
        if (response.data.success) {
            window.location.href = `/dashboard/torneos/${response.data.inscripcion.torneo.id}`;
        }
    }
    /* ----------------  */



    /* Organizer Header Buttons */
    const editAndDeleteHeaderButtons = (
        <div className="organizer_delete_edit_buttons">
            <Button label="Editar" icon="pi pi-pencil" className="p-button-raised" severity="info" onClick={(e) => {
                setCurrentAction("edit");
                updateActionsFormStatesEdit(e);
            }} />
            <Button label="Eliminar" icon="pi pi-trash" className="p-button-raised" severity="danger" onClick={handleEliminarTorneo} />
        </div>
    );

    /* ----------------  */




    /* Filtering and Searching Actions/Events for the Torneos */

    const handleBuscarTorneo = (e) => {
        const value = e.target.value;
        const found = torneos.filter(torneo => torneo.nombre.toLowerCase().includes(value.toLowerCase()));

        if ((!found.length || !value) && selectedDropdownFilter === 0) {
            setTorneos(unmutableTorneos);
            return;
        }
        if ((!found.length || !value) && selectedDropdownFilter === 1) {
            const found = unmutableTorneos.filter(torneo => equipos_usuario.some(equipo => equipo.Torneo_id === torneo.id));
            setTorneos(found);
            return;
        };
        setTorneos(found);
    };


    const handleChangeDropdownFilter = (e) => {
        const id = e.target.value;
        setSelectedDropdownFilter(id);
        if (id === 0) {
            setTorneos(unmutableTorneos);
            return;
        }
        // filter based on equipos array in user object
        const found = unmutableTorneos.filter(torneo => equipos_usuario.some(equipo => equipo.Torneo_id === torneo.id));
        setTorneos(found);
    };

    const handleChangeDepartamento = (e) => {
        const id = e.target.value;
        setSelectedDepartamento(id);
        if (currentAction === "edit") {
            setToEditData({ ...toEditData, departamento: id });
        }
        const getCiudadesData = async () => {
            const response = await getCiudades(pais, id);
            if (response.data.success) {
                setCiudades(response.data.info_lugares.ciudades);
            };

        }
        getCiudadesData();
    }

    const handleCountryChange = (e) => {
        const id = e.target.value;
        if (currentAction === "edit") {
            setToEditData({ ...toEditData, pais: id });
        }
        setSelectedPais(id);
        const getDepartamentosData = async () => {
            const response = await getDepartamentos(id);
            if (response.data.success) {
                setDepartamentos(response.data.info_lugares.departamentos);
            };
        }
        getDepartamentosData();
    }
    const onFileUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        const imagePreviewContainer = document.querySelector(".image_preview_container");
        console.log(e.target.files);
        const fileType = file["type"];
        const validImageTypes = ['image/jpeg', 'image/png'];
        console.log(file);
        if (!validImageTypes.includes(fileType)) {
            showToast({ severity: 'warn', summary: 'Advertencia', detail: 'Selecciona un archivo de imágen (.jpg, .png, jpeg, etc)', life: 3000 });
            imagePreviewContainer.innerHTML = `<span>Aquí aparecerá tu imágen subida</span>`;
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            console.log(e.target.result);
            const toSet = e.target.result.split(",")[1];
            setImagenBase64(toSet);
            if (currentAction === "edit") {
                setToEditData({ ...toEditData, foto: toSet });
            };
            imagePreviewContainer.innerHTML = `<img src="${e.target.result}" alt="preview" style="width: 100%; height: 100%; object-fit: contain" />`;
        }
        reader.readAsDataURL(file);
    }

    /* ----------------  */




    /* useEffects */

    useEffect(() => {
        const setOrganizer = () => {
            if (context.requestCompleted && context.isLogged && context.user.roles_sesione.nombre === "organizador") {
                setIsOrganizer(true);
            }
        }
        setOrganizer();
        const getTorneosData = async () => {
            if (context.user.equipos) {
                setEquiposUsuario(context.user.equipos);
                const response = await getTorneos();
                if (response.data.success) {
                    setTorneos(response.data.torneos);
                    setUnmutableTorneos(response.data.torneos);
                };
            };
        }
        getTorneosData();
    }, [context.requestCompleted, context.user, context.isLogged]);

    useEffect(() => {
        const getPaisesData = async () => {
            const response = await getPaises();
            console.log(response);
            if (response.data.success) {
                setPaises(response.data.info_lugares.paises);
            };
        }
        const getTipoDeportesData = async () => {
            const response = await getTipoDeportes();
            if (response.data.success) {
                setTipoDeportes(response.data.deportes);
            };
        }
        getTipoDeportesData();
        getPaisesData();
    }, []);
    useEffect(() => {
        const getUsuariosData = async () => {
            if (!isOrganizer) return;
            const response = await getUsuarios();
            if (response.data.success) {
                setUsuarios(response.data.usuarios);
            };
        }
        getUsuariosData();
    }, [isOrganizer]);

    /* ----------------  */



    return (
        <LoginWrapper allowed={['organizador', 'lider']}>
            <Layout>
                <div className="torneos">
                    <div className="torneos_header">
                        {isOrganizer ? <Button label="Crear torneo" icon="pi pi-plus" className="p-button-raised p-button-primary" onClick={() => {
                            setCurrentAction("add");
                            resetActionsFormStates();
                        }} /> : <Dropdown value={selectedDropdownFilter} onChange={handleChangeDropdownFilter} options={[{ name: "Todos los torneos", id: 0 }, { name: "Mis torneos", id: 1 }]} optionLabel="name" optionValue="id" placeholder="Filtrar torneos" />
                    }
                        <InputText placeholder="Buscar torneo" onChange={handleBuscarTorneo} />
                    </div>
                    <hr></hr>
                    <div className="torneos_container">
                        <div className="torneos_cards" id="torneos_cards">
                            {torneos.map(torneo => {
                                let InscribirseButton = (torneo.estado_inscripcion ? <Button label="Inscribirse" onClick={handleInscribirse} className={`p-button-raised p-button-primary inscribirse_button`} /> : <Button label="Inscribirse" className={`p-button-raised p-button-primary inscribirse_button`} disabled />)
                                if (equipos_usuario.some(equipo => equipo.Torneo_id === torneo.id)) {
                                    InscribirseButton = <Button label="Ver torneo" className={`p-button-raised p-button-primary inscribirse_button`} onClick={() => {
                                        window.location.href = `/dashboard/torneos/${torneo.id}`;
                                    }} />;
                                }
                                return (
                                    <div className="torneo_card" id={`${torneo.nombre}-${torneo.id}`} key={`${torneo.nombre}-${torneo.id}`}>
                                        <div className="torneo_header_thumbnail">
                                            <Image className="header_image" src={arrayBufferToBase64(torneo.foto.data)} alt="Image" preview />
                                        </div>
                                        <div className="torneo_card_body">
                                            <div className="torneo_card_header">
                                                <div className="torneo_header_info">
                                                    <span>
                                                        <i className="pi pi-user"></i> <p>{torneo.responsable}</p>
                                                    </span>
                                                    <span>
                                                        <i className="pi pi-credit-card"></i> <p>{torneo.moneda} <b>${parseFloat(torneo.precio_inscripcion).toFixed(2)}</b></p>
                                                    </span>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className="torneo_card_title">
                                                <h2>{torneo.nombre}</h2>
                                            </div>

                                            <div className="torneo_card_text">
                                                <p>{torneo.descripcion}</p>
                                                <span>


                                                </span>
                                            </div>
                                            <span className="torneo_card_footer">
                                                <div className="footer_labels">
                                                    <span>
                                                        <i className="pi pi-map-marker"></i> <p key={torneo.ciudade.nombre}>{torneo.ciudade.nombre}</p>
                                                    </span>
                                                    <span>
                                                        <i className="pi pi-at"></i> <p key={torneo.tipo_deporte.nombre}>{torneo.tipo_deporte.nombre}</p>
                                                    </span>
                                                    <span>
                                                        <i className="pi pi-calendar"></i> <p key={torneo.fecha_inicio + torneo.fecha_fin}>{torneo.fecha_inicio.replaceAll("-", "/")} - {torneo.fecha_fin.replaceAll("-", "/")}</p>
                                                    </span>
                                                    <span>
                                                        <i className="pi pi-check"></i> <p>{torneo.estado ? "Activo" : "Inactivo"}</p>
                                                    </span>
                                                </div>
                                                {isOrganizer ? editAndDeleteHeaderButtons : InscribirseButton}
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <aside className="torneos_aside_container">
                            <h3>{torneos.length} TORNEO(S) DISPONIBLES</h3>
                            <div className="torneos_aside">
                                <div className="torneos_aside_titulos" key="torneos_aside_titulos">
                                    {torneos.map((torneo, i) => {
                                        return (
                                            <>
                                                <span key={`${torneo.nombre}-${torneo.id}`}>{torneo.estado ? "🟢" : "🔴"} <a href={`#${torneo.nombre}-${torneo.id}`}>{torneo.nombre}</a> (<u>{torneo.estado_inscripcion ? "Inscripción Activa" : "Inscripción Inactiva"})</u></span>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>




                {/* Dialog for adding or editing a torneo */}

                <Dialog visible={visibleAddTournament} modal header={headerElement} footer={footerContent(currentAction)} style={{ width: '50rem' }} breakpoints={{ '1100px': '75vw', '960px': '100vw' }} onHide={() => { if (!visibleAddTournament) return; setVisibleAddTournament(false); }}>
                    <form className="tournament_add_form">
                        <div className="tournament_add_form_group">
                            {/* add a country dropdown with label*/}
                            <span>
                                <label htmlFor="country">País: </label>
                                <Dropdown id="country" optionLabel="name" optionValue="id" value={pais} options={paises.map(pais => {
                                    return { name: pais.nombre, id: pais.id }
                                })} placeholder="Seleccione un país" onChange={handleCountryChange} />
                            </span>
                            {/* add a province dropdown with label*/}
                            <span>
                                <label htmlFor="departamento">Departamento: </label>
                                <Dropdown id="departamento" value={departamento} optionLabel="name" onChange={handleChangeDepartamento} optionValue="id" options={departamentos.map(departamento => {
                                    return { name: departamento.nombre, id: departamento.id }
                                })} placeholder="Seleccione un departamento" />

                            </span>
                        </div>
                        {/* add a city dropdown with label*/}
                        <div className="tournament_add_form_group">
                            <span>
                                <label htmlFor="city">Ciudad: </label>
                                <Dropdown id="city" optionLabel="name" value={ciudad} optionValue="id" onChange={(e) => {
                                    const id = e.target.value;
                                    if (currentAction === "edit") {
                                        setToEditData({ ...toEditData, ciudad_id: id });
                                    }
                                    setSelectedCiudad(id);
                                    console.log(id);
                                }} options={ciudades.map(ciudad => {
                                    return { name: ciudad.nombre, id: ciudad.id }
                                })} placeholder="Seleccione una ciudad" />
                            </span>
                            <span>
                                <label htmlFor="sport">Deporte: </label>
                                <Dropdown id="sport" value={deporte} optionLabel="name" optionValue="id" onChange={(e) => {
                                    const id = e.target.value;
                                    if (currentAction === "edit") {
                                        setToEditData({ ...toEditData, tipo_deporte_id: id });
                                    }
                                    setSelectedDeporte(id);
                                }} options={deportes.map(deporte => {
                                    return { name: deporte.nombre, id: deporte.id }
                                })} placeholder="Seleccione un deporte" />
                            </span>
                        </div>
                        {/* add a name input with label*/}
                        <div className="tournament_add_form_group">
                            <div>
                                <label htmlFor="name">Nombre</label>
                                <InputText id="name" onBlur={(e) => {
                                    const value = e.target.value;
                                    if (currentAction === "edit") {
                                        setToEditData({ ...toEditData, nombre: value });
                                    }
                                    setNombre(value);
                                }} />
                            </div>
                            <div>
                                <label htmlFor="responsable">Responsable</label>
                                <InputText id="responsable" onBlur={(e) => {
                                    const value = e.target.value;
                                    if (currentAction === "edit") {
                                        setToEditData({ ...toEditData, responsable: value });
                                    }
                                    setResponsable(value);
                                }} />
                            </div>
                        </div>
                        {/* add a description input with label*/}
                        <div className="tournament_add_form_groups">
                            <label htmlFor="description">Descripción</label>
                            <InputTextarea className="textarea_torneos" variant="filled" id="description" onBlur={(e) => {
                                const value = e.target.value;
                                if (currentAction === "edit") {
                                    setToEditData({ ...toEditData, descripcion: value });
                                }
                                setDescripcion(value);
                            }} cols={30} />
                        </div>
                        <div className="tournament_add_form_single">
                            <label htmlFor="description">Ubicación {"(pegar url)"}</label>
                            <InputText variant="filled" id="ubicacion" onBlur={(e) => {
                                const value = e.target.value;
                                if (currentAction === "edit") {
                                    setToEditData({ ...toEditData, ubicacion: value });
                                }
                                setUbicacion(value);
                            }} />
                        </div>
                        {/* add a price input with label*/}
                        <div className="tournament_add_form_group">
                            <div>
                                <label htmlFor="price">Precio</label>
                                <InputNumber id="price" mode="currency" currency="COP" locale="es-CO" style={{ width: "100%" }} onValueChange={(e) => {
                                    console.log(e.value);
                                    const value = e.value;
                                    if (currentAction === "edit") {
                                        setToEditData({ ...toEditData, precio: value });
                                    }
                                    setPrecio(value);
                                }} />
                            </div>
                            <div>
                                <label htmlFor="date">Moneda</label>
                                <Dropdown value={moneda} onChange={(e) => {
                                    const name = e.target.value;
                                    if (currentAction === "edit") {
                                        setToEditData({ ...toEditData, moneda: name });
                                    }
                                    setMoneda(name);
                                }} id="moneda" optionLabel="desc" optionValue="name" options={[{ name: "COP", desc: "Pesos Colombianos (COP)" }, { name: "USD", desc: "Dolar Estadounidense (USD)" }]} placeholder="Selecciona una moneda" style={{ width: "100%" }} />
                            </div>
                        </div>
                        <div className="tournament_add_form_group">
                            <div>
                                <label htmlFor="estado">Estado</label>
                                {/* dropdown with Inactive or Active */}
                                <Dropdown value={estado} onChange={(e) => {
                                    const id = e.target.value;
                                    if (currentAction === "edit") {
                                        setToEditData({ ...toEditData, estado: id });
                                    }
                                    setSelectedEstado(id);
                                }} id="estado" optionLabel="name" optionValue="id" placeholder="Seleccione un estado" style={{ width: "100%" }} options={[{ name: "Activo", id: 1 }, { name: "Inactivo", id: 0 }]} />
                            </div>
                            <div>
                                <label htmlFor="date">Estado de Inscripción</label>
                                <Dropdown value={estado_inscripcion} onChange={(e) => {
                                    const id = e.target.value;
                                    if (currentAction === "edit") {
                                        setToEditData({ ...toEditData, estado_inscripcion: id });
                                    }
                                    setSelectedEstadoInscripcion(id);
                                }} id="estado" optionLabel="name" optionValue="id" placeholder="Seleccione un estado" style={{ width: "100%" }} options={[{ name: "Activo", id: 1 }, { name: "Inactivo", id: 0 }]} />
                            </div>
                        </div>
                        <div className="tournament_add_form_group">
                            <div>
                                <label htmlFor="referee">Arbitro</label>
                                {/* add a dropdown for the referee */}
                                <Dropdown value={usuario} id="referee" optionLabel="usuario" optionValue="id" placeholder="Seleccione un árbitro" style={{ width: "100%" }} onChange={(e) => {
                                    const id = e.target.value;
                                    if (currentAction === "edit") {
                                        setToEditData({ ...toEditData, arbitro_id: id });
                                    }
                                    setSelectedUsuario(id);
                                }} options={usuarios} />
                            </div>
                            <div>
                                <label htmlFor="date">Fechas Estipuladas</label>
                                <Calendar value={dates} id="date" selectionMode="range" onChange={(e) => {
                                    setDates(e.value);
                                    if (currentAction === "edit") {
                                        setToEditData({ ...toEditData, fecha_inicio: e.value[0], fecha_fin: e.value[1] });
                                    }
                                    console.log(e.value);
                                }} readOnlyInput hideOnRangeSelection style={{ width: "100%" }} />
                            </div>
                        </div>
                        <div className="image_upload_add_form">
                            <label htmlFor="image">Imágen del torneo</label>

                            <div className="image_preview_container">
                                <span>Aquí aparecerá tu imágen subida</span>
                            </div>
                            <label className="custom-file-upload">
                                <input type="file" onChange={onFileUpload} accept="image/*" />
                                <i className="pi pi-arrow-up"></i>Subir Imágen
                            </label>
                        </div>
                        <ReCAPTCHA className='g-recaptcha'
                            ref={recaptchaRef}
                            sitekey="6LcQqe4pAAAAAEXsdoUw_Pptf3CpxLuz0wzrkXSF"
                        />
                    </form>
                </Dialog>

                {/* ----------------  */}
            </Layout>
        </LoginWrapper>
    );
};

export default Torneos;