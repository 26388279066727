

import { Toast } from 'primereact/toast';
import { toastContext } from '../../index.js'
import { useLayoutEffect, useRef } from 'react';


const ToastContextProvider = ({ children }) => {
    const toast = useRef(null);
    useLayoutEffect(() => {
        window.PrimeToast = showToast || {};
    }, [])
    const showToast = (options) => {
        if (!toast.current) return;
        toast.current.show(options);    
    }
    return (
        <toastContext.Provider value={{ showToast }}>
            <Toast ref={toast} />
            {children}
        </toastContext.Provider>
    );

};

export default ToastContextProvider;