
import { Menubar } from 'primereact/menubar';
import { googleLogout } from "@react-oauth/google";
import { adminItems, leaderItems, organizerItems } from "./NavbarItems.js";
import axiosInstance from '../../utils/requests/axios.instance.js';

const Navbar = ({ context, centerNavbar }) => {
    const { user } = context;
    let rolItems;
    switch(user.rol_id) {
        case 1:
            rolItems = organizerItems;
            break;
        case 2:
            rolItems = adminItems;
            break;
        case 3:
            rolItems = leaderItems;
            break;
        default:
            rolItems = [];
    }
    const items = [
        ...rolItems,
        {
            label: "Cerrar Sesión",
            icon: "pi pi-sign-out",
            command: async () => {
                googleLogout();
                const response = await axiosInstance.post("/api/auth/logout");
                if (response.data.success) {
                    window.location.href = "/login";
                };
            
            }
        }
    ];

    return (
        <div className="card">
            <Menubar model={items} style={{width: "100%"}}/>
        </div>
    )
}
        

export default Navbar;