import LoginWrapper from "../../../../utils/authentication/LoginWrapper.js";
import Layout from "../../../dashboard/Layout.js";
import { deleteDeportistas, editDeportista } from "../../../../utils/http_requests/index.js";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState, useEffect, useContext, createRef } from "react";
import { addDeportista, getDeportistas } from "../../../../utils/http_requests/index.js";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { loginContext, toastContext } from "../../../../index.js";
import { FilterOperator } from "primereact/api";
import "./MisDeportistas.css";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import ReCAPTCHA from "react-google-recaptcha";


const MisDeportistas = () => {
    const recaptchaRef = createRef();
    const { showToast } = useContext(toastContext);
    const [deportistas, setDeportistas] = useState((prevState, newValue) => {
        if(prevState === newValue) return prevState;
        return newValue;
    });
    const [deportistaDialog, setDeportistaDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedDeportista, setSelectedDeportista] = useState(null);
    const [editDeportistaState, setEditDeportistaState] = useState(null);
    const [selectedDeportistas, setSelectedDeportistas] = useState([]);
    const [toEditData, setToEditData] = useState({});
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState(null);
    const [nombre, setNombre] = useState(null);
    const [apellido, setApellido] = useState(null);
    const [documento, setDocumento] = useState(null);
    const [telefono, setTelefono] = useState(null);
    const [email, setEmail] = useState(null);
    const [fecha_nacimiento, setFechaNacimiento] = useState(null);
    const [rh, setRh] = useState(null);
    const [fecha_sys, setFechaSys] = useState(new Date());

    const { requestCompleted, user } = useContext(loginContext);


    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        console.log(_filters);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            nombre: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            apellido: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            documento: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            telefono: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            fecha_nacimiento: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            rh: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            fecha_sys: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
        });
        setGlobalFilterValue('');

    };

    const resetDeportistaForm = () => {
        setNombre(null);
        setApellido(null);
        setDocumento(null);
        setTelefono(null);
        setEmail(null);
        setFechaNacimiento(null);
        setRh(null);
    };
    const handleEliminarDeportistas = async () => {
        const deportistas_ids = selectedDeportistas.map(deportista => deportista.id);
        // show a confirmation dialog
        const confirm = window.confirm("¿Estás seguro de que deseas eliminar los deportistas seleccionados?");
        if (confirm) {
            const response = await deleteDeportistas(deportistas_ids);
            if (response.data.success) {
                // remove the deleted deportistas from the deportistas state
                setDeportistas(deportistas.filter(deportista => !deportistas_ids.includes(deportista.id)));
                setSelectedDeportistas([]);
            }
        }
    };

    const handleEditarDeportistaDialog = async () => {
        setEditDeportistaState(true);
        setDeportistaDialog(true);
        console.log(selectedDeportista.nombre);
        if (selectedDeportista) {
            setNombre(selectedDeportista.nombre);
            setApellido(selectedDeportista.apellido);
            setDocumento(selectedDeportista.documento);
            setTelefono(selectedDeportista.telefono);
            setEmail(selectedDeportista.email);
            setFechaNacimiento(new Date(selectedDeportista.fecha_nacimiento));
            setRh(selectedDeportista.rh);

        };
    };

    const handleAñadirDeportista = async () => {
        const captcha_token = recaptchaRef.current.getValue();
        // nombre, apellido, documento, telefono, email, fecha_nacimiento, rh, captcha_token
        const data = { nombre, apellido, documento, telefono, email, fecha_nacimiento, rh, captcha_token };
        for (const key in data) {
            if (!data[key]) {
                showToast({
                    severity: 'error',
                    summary: 'Error',
                    detail: <span>{key === "captcha_token" ? "Se debe completar el captcha" : "Todos los campos son requeridos"}. El campo <strong>{key}</strong> no puede estar vacío</span>
                });
                return;
            }
        }
        try {
            const response = await addDeportista(nombre, apellido, documento, telefono, email, fecha_nacimiento, rh, fecha_sys, captcha_token);
            if (response.data.success) {
                const formatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
                let fecha_sys_date = new Date(response.data.createdDeportista.fecha_sys);
                let fecha_nacimiento_date = new Date(response.data.createdDeportista.fecha_nacimiento);
                fecha_sys_date = fecha_sys_date.toLocaleDateString('es-ES', formatOptions);
                fecha_nacimiento_date = fecha_nacimiento_date.toLocaleDateString('es-ES', formatOptions);

                setDeportistaDialog(false);
                // modify deportistas state
                setDeportistas([...deportistas, {
                    id: response.data.createdDeportista.id,
                    nombre,
                    apellido,
                    documento,
                    telefono,
                    email,
                    fecha_nacimiento: fecha_nacimiento_date,
                    rh,
                    fecha_sys: fecha_sys_date
                }]);
            }
        } catch (error) {
            recaptchaRef.current.reset();
            console.error(error);
        }
    };

    const handleEditarDeportista = async () => {
        const captcha_token = recaptchaRef.current.getValue();
        const deportista_id = selectedDeportista.id;
        try {
            const response = await editDeportista(toEditData, captcha_token, deportista_id);
            if (response.data.success) {
                const updatedDeportista = response.data.updatedDeportista;
                const formatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
                let fecha_sys_date = new Date(updatedDeportista.fecha_sys);
                let fecha_nacimiento_date = new Date(updatedDeportista.fecha_nacimiento);
                fecha_sys_date = fecha_sys_date.toLocaleDateString('es-ES', formatOptions);
                fecha_nacimiento_date = fecha_nacimiento_date.toLocaleDateString('es-ES', formatOptions);

                setDeportistas(deportistas.map(deportista => {
                    if (deportista.id === updatedDeportista.id) {
                        deportista = {
                            id: updatedDeportista.id,
                            nombre: updatedDeportista.nombre,
                            apellido: updatedDeportista.apellido,
                            documento: updatedDeportista.documento,
                            telefono: updatedDeportista.telefono,
                            email: updatedDeportista.email,
                            fecha_nacimiento: fecha_nacimiento_date,
                            rh: updatedDeportista.rh,
                            fecha_sys: fecha_sys_date
                        }
                    }
                    return deportista;
                }));
                setDeportistaDialog(false);

            }
        } catch (error) {
            recaptchaRef.current.reset();
            console.error(error);
        }
    }

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 justify-content-between align-items-center" style={
                { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem', flexWrap: 'wrap' }
            }>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} className="input_deportistas" onChange={onGlobalFilterChange} placeholder="Buscar deportista por palabra clave" />
                </IconField>
                <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                    <Button label="Añadir Deportista" className="header_buttons_deportistas" icon="pi pi-plus" severity="success" outlined onClick={() => {
                        resetDeportistaForm();
                        setDeportistaDialog(true);
                    }} />
                    <Button label="Eliminar Deportista/s" className="header_buttons_deportistas" icon="pi pi-trash" onClick={handleEliminarDeportistas} severity="danger" outlined disabled={!selectedDeportistas || !selectedDeportistas.length} />
                    <Button label="Editar Deportista" onClick={handleEditarDeportistaDialog} className="header_buttons_deportistas" icon="pi pi-pencil" severity="warning" outlined disabled={!selectedDeportistas || selectedDeportistas.length !== 1} />
                </div>
            </div>
        );
    };
    const header = renderHeader();

    const deportistaDialogHeader = (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: "5px 0"
        }}>
            {editDeportistaState ? <Button label="Editar" icon="pi pi-pencil" onClick={handleEditarDeportista} /> : <Button label="Guardar" icon="pi pi-save" onClick={handleAñadirDeportista} />}
            <Button label="Cancelar" icon="pi pi-times" onClick={() => {
                setDeportistaDialog(false);
            }} />
        </div>
    );

    useEffect(() => {
        const fetchDeportistas = async () => {
            const deportistasData = await getDeportistas();
            if (deportistasData.data.success) {
                setDeportistas(deportistasData.data.deportistas.map(deportista => {
                    const formatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
                    let fecha_sys_date = new Date(deportista.fecha_sys);
                    let fecha_nacimiento_date = new Date(deportista.fecha_nacimiento);
                    fecha_sys_date = fecha_sys_date.toLocaleDateString('es-ES', formatOptions);
                    fecha_nacimiento_date = fecha_nacimiento_date.toLocaleDateString('es-ES', formatOptions);

                    return {
                        id: deportista.id,
                        nombre: deportista.nombre,
                        apellido: deportista.apellido,
                        documento: deportista.documento,
                        telefono: deportista.telefono,
                        email: deportista.email,
                        fecha_nacimiento: fecha_nacimiento_date,
                        rh: deportista.rh,
                        fecha_sys: fecha_sys_date
                    }
                }));
            }
        }
        if (requestCompleted && user) {
            initFilters();
            setLoading(false);
            fetchDeportistas();
        }
    }, [requestCompleted, user]);


    return (
        <LoginWrapper allowed={"lider"} >
            <Layout>
                <div className="deportistas_container">
                    <div className="card">
                        <DataTable value={deportistas} tableStyle={{ minWidth: '50rem' }} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 40]} loading={loading} selectionMode="checkbox" dataKey="id" header={header} filters={filters} globalFilterFields={['nombre', 'apellido', 'documento', 'telefono', 'email', 'fecha_nacimiento', 'rh', 'fecha_sys']} selection={selectedDeportistas} onSelectionChange={(e) => {
                            setSelectedDeportistas(e.value);
                            console.log(e.value);
                            if (e.value.length === 1) {
                                setSelectedDeportista(e.value[0]);
                            }
                        }} emptyMessage="No se encontraron deportistas">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="id" header="#" sortable></Column>
                            <Column field="nombre" header="Nombre(s)" filterField='nombre'></Column>
                            <Column field="apellido" header="Apellido(s)" filterField='apellido'></Column>
                            <Column field="documento" header="Documento" filterField='documento'></Column>
                            <Column field="telefono" header="Teléfono" filterField='telefono'></Column>
                            <Column field="email" header="Correo Electrónico" filterField='email'></Column>
                            <Column field="fecha_nacimiento" header="Fecha de Nacimiento" filterField='fecha_nacimiento' sortable dataType='date'></Column>
                            <Column field="rh" header="RH" filterField='rh'></Column>
                            <Column field="fecha_sys" header="Fecha de Creación" filterField='fecha_sys' sortable dataType='date'></Column>
                        </DataTable>
                    </div>
                </div>
            </Layout>
            <Dialog header="Deportista" visible={deportistaDialog} style={{ width: '50vw' }} onHide={() => setDeportistaDialog(false)} footer={deportistaDialogHeader}>
                <form className="deportistas_form">
                    <div>
                        <span>
                            <label htmlFor="nombre">Nombre(s)</label>
                            <InputText value={nombre || ""} className="deportistas_inputs" id="nombre" name="nombre" onChange={(e) => {
                                if (editDeportistaState) setToEditData({ ...toEditData, nombre: e.target.value });
                                setNombre(e.target.value)
                            }

                            } />
                        </span>
                        <span>
                            <label htmlFor="apellido">Apellido(s)</label>
                            <InputText value={apellido || ""} className="deportistas_inputs" id="apellido" name="apellido" onChange={(e) => {
                                if (editDeportistaState) setToEditData({ ...toEditData, apellido: e.target.value });
                                setApellido(e.target.value);
                            }} />
                        </span>
                    </div>
                    <div>
                        <span>
                            <label htmlFor="documento">Documento</label>
                            <InputText value={documento || ""} className="deportistas_inputs" id="documento" name="documento" onChange={(e) => {
                                if (editDeportistaState) setToEditData({ ...toEditData, documento: e.target.value });
                                setDocumento(e.target.value);
                            }} />
                        </span>
                        <span>
                            <label htmlFor="telefono">Teléfono</label>
                            <InputMask value={telefono || ""} className="deportistas_inputs" id="telefono" mask="999-9999999" placeholder="318-6xxxxxx" name="telefono" onChange={(e) => {
                                if (editDeportistaState) setToEditData({ ...toEditData, telefono: e.target.value });
                                setTelefono(e.target.value);

                            }} />
                        </span>
                    </div>
                    <div>
                        <span>
                            <label htmlFor="fecha_nacimiento">Fecha de Nacimiento</label>
                            <Calendar value={fecha_nacimiento || ""} readOnlyInput className="deportistas_inputs" id="fecha_nacimiento" name="fecha_nacimiento" onChange={(e) => {
                                if (editDeportistaState) setToEditData({ ...toEditData, fecha_nacimiento: e.target.value });
                                setFechaNacimiento(e.target.value);
                            }} />
                        </span>
                        <span>
                            <label htmlFor="rh">RH</label>
                            <Dropdown value={rh || ""} className="deportistas_inputs" placeholder="Selecciona el RH" id="rh" name="rh" options={[{ label: "A+", value: "A+" }, { label: "A-", value: "A-" }, { label: "B+", value: "B+" }, { label: "B-", value: "B-" }, { label: "AB+", value: "AB+" }, { label: "AB-", value: "AB-" }, { label: "O+", value: "O+" }, { label: "O-", value: "O-" }]} onChange={(e) => {
                                if (editDeportistaState) setToEditData({ ...toEditData, rh: e.value });
                                setRh(e.value);
                            }} />
                        </span>
                    </div>
                    <div>
                        <span>
                            <label htmlFor="email">Correo Electrónico</label>
                            <InputText value={email || ""} className="deportistas_inputs" id="email" name="email" onChange={(e) => {
                                if (editDeportistaState) setToEditData({ ...toEditData, email: e.target.value });
                                setEmail(e.target.value);
                            }
                            } />

                        </span>
                    </div>
                    <div>
                        <ReCAPTCHA className='g-recaptcha'
                            ref={recaptchaRef}
                            sitekey="6LcQqe4pAAAAAEXsdoUw_Pptf3CpxLuz0wzrkXSF"
                        />
                    </div>
                </form>
            </Dialog>
        </LoginWrapper>
    )
}

export default MisDeportistas;