import './AuthForms.css';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin } from '@react-oauth/google';
import { useState, createRef, useEffect, useContext } from 'react';
import { loginContext, toastContext } from '../../index.js';
import axiosInstance from '../../utils/requests/axios.instance.js';


const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
const getCookie = (name) => {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
} 


const AuthForms = ({ reason }) => {
    const { isLogged, requestCompleted } = useContext(loginContext);
    const { showToast } = useContext(toastContext);

    const returnParsedReason = (first, second) => {
        return reason === 'login' ? second : first;
    }
    const recaptchaRef = createRef();
    const [errorMessage, setErrorMessage] = useState(<></>);
    const [usuario, setUsuario] = useState([]);
    const [checked, setChecked] = useState(false);
    const [visible, setVisible] = useState(false);
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUsuario(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });
    const showSuccess = () => {
        showToast({ severity: 'success', summary: 'Exito', detail: 'Has sido logeado correctamente, redirigiéndote...', life: 2000 });
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        const token = recaptchaRef.current.getValue();
        const url = reason === "login" ? "/api/auth/login" : "/api/auth/signup";
        const data = new FormData(e.target);
        const usuario = data.get("usuario");
        const contrasena = data.get("contrasena");

        const response = await axiosInstance.post(url, {
            usuario,
            contrasena,
            captcha_token: token
        });
        if (response.data.success) {
            showSuccess();
            await wait(2000);
           window.location.href = "/dashboard";
        } else {
            recaptchaRef.current.reset();
        }        
    };
    useEffect(() => {
        const handleGoogleLogin = async () => {
            if (usuario.length !== 0) {
                try {
                    const response = await axiosInstance.post("/api/auth/oauth/verify", {
                        accessToken: usuario.access_token,
                    });
                    console.log(response);
                    if (response.data.success) {
                        showSuccess();
                        await wait(2000);
                        window.location.href = "/dashboard";
                    }
                } catch (err) {
                    if (!err.response.data.message) {
                        setErrorMessage(<p>✖️ Al parecer el servidor se encuentra caído, contáctese con el administrador de la página.</p>);
                        setVisible(true);
                        return;
                    }
                    setErrorMessage(err.response.data.message);
                    setVisible(true);
                }
            }
        }
        const checkIfLogged = async () => {
            if (requestCompleted && isLogged) {
                showSuccess();
                await wait(2000);
                window.location.href = "/dashboard";
            }
        }
        checkIfLogged();
        handleGoogleLogin();
        // eslint-disable-next-line
    }, [usuario, requestCompleted, isLogged])
    return (
        <div className='auth_form'>
            <form className='auth_form_container' onSubmit={onSubmit}>
                <div className='auth_inputs'>
                    <div className="form_label">
                        <label htmlFor="usuario">Nombre de usuario</label>
                        <InputText id="usuario" name="usuario" aria-describedby="usuario-help" />
                    </div>
                    <div className="form_label">
                        <label htmlFor="contrasena">Contraseña</label>
                        <InputText id="contrasena" name="contrasena" type='password' aria-describedby="username-help" />
                    </div>
                    <ReCAPTCHA className='g-recaptcha'
                        ref={recaptchaRef}
                        sitekey="6LcQqe4pAAAAAEXsdoUw_Pptf3CpxLuz0wzrkXSF"
                    />
                    <div className="input_checkbox">
                        <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
                        <label>Acepta el uso de cookies</label>
                    </div>
                </div>
                <div className='button_submit'>
                    <Button label={returnParsedReason("Registrarse", "Iniciar Sesión")} type='submit' />
                    <Button label={`${returnParsedReason("Registrarse", "Iniciar Sesión")} con Google 🚀`} onClick={login} type='button' />
                </div>
                <div className='link_form_auth'>
                    <Link to={returnParsedReason("/login", "/signup")}>{returnParsedReason("Iniciar Sesión", "Registrarse")}</Link>
                </div>

            </form>
            <Dialog header={<span><i className="pi pi-exclamation-circle"></i> ERROR</span>} visible={visible} style={{ width: '50vw' }} onHide={() => { if (!visible) return; setVisible(false); }}>
                {errorMessage}
            </Dialog>
        </div>
    )

}

export default AuthForms;