import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from 'primereact/iconfield';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import LoginWrapper from '../../../../utils/authentication/LoginWrapper';
import { getUsuarios, getRoles, createUser, deleteUsers, editUser } from "../../../../utils/http_requests/index.js";
import Layout from '../../../dashboard/Layout.js';
import './Usuarios.css';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ReCAPTCHA from "react-google-recaptcha";
import { Dropdown } from 'primereact/dropdown';
import { loginContext, toastContext } from '../../../../index.js';

const Usuarios = () => {
    const { showToast } = useContext(toastContext);
    const { requestCompleted, user } = useContext(loginContext);
    const recaptchaRef = createRef();
    const [selectedCity, setSelectedCity] = useState(null);
    const [usuarios, setUsuarios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState(null);
    const [addUsersVisible, setAddUsersVisible] = useState(false);
    const [editUsersVisible, setEditUsersVisible] = useState(false);
    const [roles, setRoles] = useState([{}]);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [selectedUser, setSelectedUser] = useState('');


    const handleSubmitUser = async (e) => {
        e.preventDefault();
        const usuario = document.getElementById('username').value;
        const contrasena = document.getElementById('password').value;
        const rol = selectedCity;
        const token = recaptchaRef.current.getValue();
        if (!token) {
            showToast({ severity: 'error', summary: 'Error', detail: 'Por favor, complete el captcha.', life: 3000 });
            return;
        }
        if (!usuario || !contrasena || !selectedCity) {
            showToast({ severity: 'error', summary: 'Error', detail: 'Por favor, rellene todos los campos.', life: 3000 });
            return;
        };
        
        try {
            const newUser = await createUser(usuario, contrasena, rol.id, token);
            console.log(newUser.data.success);
            if (newUser.data.success) {
                let fecha_sys_formatted = new Date(newUser.data.user_info.fecha_sys);
                fecha_sys_formatted = fecha_sys_formatted.toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                console.log(newUser);
                setAddUsersVisible(false);
                setUsuarios([...usuarios, {
                    id: newUser.data.user_info.id, usuario: newUser.data.user_info.nombre, rol: rol.nombre, tipo_cuenta: 'normal', fecha_sys: fecha_sys_formatted
                }]);
            }
        } catch (err) {
            recaptchaRef.current.reset();
        }
    };
    const handleEditUser = async (e) => {
        e.preventDefault();
        const rol = selectedCity;
        const token = recaptchaRef.current.getValue();
        const id = selectedProducts[0].id;
        if (!rol) {
            showToast({ severity: 'error', summary: 'Error', detail: 'Por favor, seleccione un rol.', life: 3000 });
            return;
        };
        if (!token) {
            showToast({ severity: 'error', summary: 'Error', detail: 'Por favor, complete el captcha.', life: 3000 });
            return;
        }

        try {
            const editedUser = await editUser(id, rol.id, token);
            if(editedUser.data.success) {
                let newUsers = usuarios.map(user => {
                    if (user.id === id) {
                        user.rol = rol.nombre;
                    }
                    return user;
                });
                setUsuarios(newUsers);
                setEditUsersVisible(false);
            }
            
        } catch (err) {
            recaptchaRef.current.reset();
        }
    };
    const footerContent = (setStateVariable, handleSomething) => (
        <div>
            <Button label="Cancelar" icon="pi pi-times" onClick={() => setStateVariable(false)} className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={handleSomething} autoFocus />
        </div>
    );
    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            usuario: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            rol: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            tipo_cuenta: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            fecha_sys: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });
        setGlobalFilterValue('');
        // Already added filter for each column, but only usuario filter works. What should I do? R/
        // then how
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        console.log(_filters);
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const handleEliminarUsuarios = async () => {
        if (!selectedProducts || !selectedProducts.length) {
            showToast({ severity: 'error', summary: 'Error', detail: 'Por favor, seleccione al menos un usuario para eliminar.', life: 3000 });
            return;
        }
        const ids = selectedProducts.map(product => product.id);
        const response = await deleteUsers(ids);
        console.log(response);
        if (response.data.success) {
            let newUsers = usuarios.filter(user => !ids.includes(user.id));
            setUsuarios(newUsers);
            setSelectedProducts(null);
        }

    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 justify-content-between align-items-center" style={
                { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem', flexWrap: 'wrap' }
            }>
                <div style={{ display: "flex", gap: "20px" }}>
                    <Button label="Añadir Usuario" icon="pi pi-plus" severity="success" outlined onClick={() => setAddUsersVisible(true)} />
                    <Button label="Eliminar Usuario/s" icon="pi pi-trash" severity="danger" outlined disabled={!selectedProducts || !selectedProducts.length} onClick={handleEliminarUsuarios} />
                    <Button label="Editar Usuario" icon="pi pi-pencil" severity="warning" outlined disabled={!selectedProducts || selectedProducts.length !== 1} onClick={() => setEditUsersVisible(true)} />
                </div>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar usuario por palabra clave" />
                </IconField>
            </div>
        );
    };
    const header = renderHeader();
    useEffect(() => {
        const fetchUsuarios = async () => {
            const usuariosData = await getUsuarios();
            if (usuariosData.data.success) {
                setUsuarios(usuariosData.data.usuarios.filter(usuario => usuario.id !== user.id).map(usuario => {
                    // format fecha_sys to string yyyy-mm-dd
                    let usu = new Date(usuario.fecha_sys);
                    usu = usu.toLocaleDateString('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    });
                    return {
                        id: usuario.id,
                        usuario: usuario.usuario,
                        rol: usuario.roles_sesione.nombre,
                        tipo_cuenta: usuario.tipo_cuenta.descripcion,
                        fecha_sys: usu
                    }
                }
                ));
                setLoading(false);
            }
        }
        const fetchRoles = async () => {
            const rolesData = await getRoles();
            if(rolesData.data.success) {
                setRoles(rolesData.data.roles);
            }
        }
        if (requestCompleted && user) {
            initFilters();
            fetchUsuarios();
            fetchRoles();
        }
    }, [requestCompleted, user]);
    return (
        <LoginWrapper allowed={"administrador"}>
            <Layout>
                <div className="users_container">
                    <div className="card">
                        <DataTable value={usuarios} tableStyle={{ minWidth: '50rem' }} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 40]} loading={loading} selectionMode="checkbox" dataKey="id" header={header} filters={filters} globalFilterFields={['usuario', 'fecha_sys', 'rol', 'tipo_cuenta']} selection={selectedProducts} onSelectionChange={(e) => {
                            setSelectedProducts(e.value);
                            console.log(e.value);
                            if (e.value.length) {
                                setSelectedUser(e.value[0].usuario);
                            }
                        }} emptyMessage="No se encontraron usuarios">
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            <Column field="id" header="#" sortable></Column>
                            <Column field="usuario" header="Usuario" filterField='usuario'></Column>
                            <Column field="rol" header="Nombre de Rol" filterField='rol'></Column>
                            <Column field="tipo_cuenta" header="Tipo de Cuenta" filterField='tipo_cuenta'></Column>
                            <Column field="fecha_sys" header="Fecha de Creación" filterField='fecha_sys' sortable dataType='date'></Column>
                        </DataTable>
                    </div>
                </div>
            </Layout>
            <Dialog header={"Usuario"} visible={addUsersVisible} style={{ width: '50vw' }} onHide={() => { if (!addUsersVisible) return; setAddUsersVisible(false); }} footer={footerContent(setAddUsersVisible, handleSubmitUser)} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <form onSubmit={handleSubmitUser} >
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <div>
                                <label htmlFor="usuario">Nombre de Usuario</label>
                                <InputText id="username" name="usuario" aria-describedby="username-help" style={{ width: "100%" }} />
                            </div>
                            <div>
                                <label htmlFor="usuario">Contraseña</label>
                                <InputText id="password" name="password" type='password' aria-describedby="username-help" style={{ width: "100%" }} />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="rol">Rol del Usuario</label>
                            <Dropdown inputId="rol" value={selectedCity} style={{ width: "100%" }} onChange={(e) => setSelectedCity(e.value)} options={roles} optionLabel="nombre" name='rol' placeholder="Roles Disponibles" className="w-full md:w-14rem" />
                        </div>
                        <div>
                            <ReCAPTCHA className='g-recaptcha'
                                ref={recaptchaRef}
                                sitekey="6LcQqe4pAAAAAEXsdoUw_Pptf3CpxLuz0wzrkXSF"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
            <Dialog header={"Usuario"} visible={editUsersVisible} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} onHide={() => { if (!editUsersVisible) return; setEditUsersVisible(false); }} footer={footerContent(setEditUsersVisible, handleEditUser)}>
                <form onSubmit={handleSubmitUser} >
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <div>
                            <label htmlFor="usuario">Nombre de Usuario</label>
                            <InputText id="username" disabled value={selectedUser} name="usuario" aria-describedby="username-help" style={{ width: "100%" }} />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                            <label htmlFor="rol">Rol del Usuario</label>
                            <Dropdown inputId="rol" value={selectedCity} style={{ width: "100%" }} onChange={(e) => setSelectedCity(e.value)} options={roles} optionLabel="nombre" name='rol' placeholder="Roles Disponibles" className="w-full md:w-14rem" />
                        </div>
                        <div>
                            <ReCAPTCHA className='g-recaptcha'
                                ref={recaptchaRef}
                                sitekey="6LcQqe4pAAAAAEXsdoUw_Pptf3CpxLuz0wzrkXSF"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </LoginWrapper>
    )
}

export default Usuarios;