import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { loginContext } from "../../../../index.js";
import LoginWrapper from "../../../../utils/authentication/LoginWrapper";
import { Menubar } from "primereact/menubar";
import Navbar from "../../../navbar/Navbar.js";
import Layout from "../../../dashboard/Layout.js";

const TorneosDashboard = () => {
    let items = [
        {
            label: "Deportistas",
            icon: "pi pi-fw pi-users",
            command: () => { window.location.href = "/dashboard/deportistas" }
        },
        {
            label: "Pagos",
            icon: "pi pi-fw pi-money-bill",
            command: () => { window.location.href = "/dashboard/pagos" }
        }
    ];
    const { user, requestCompleted } = useContext(loginContext);
    const { id_torneo } = useParams();
    useEffect(() => {
        if(requestCompleted && user) {
            const inscriptionStatus = user.equipos.some(equipo => equipo.Torneo_id == id_torneo);
            if(!inscriptionStatus) {
                window.location.replace("/dashboard/torneos");
            }
        }
    }, [requestCompleted, user]);
    return (
        <LoginWrapper allowed={["organizador", "lider"]}>
            <Layout centerNavbar={true}>
                <Menubar model={items}/>
                <div className="p-grid p-justify-center">
                    <div className="p-col-12 p-md-8">
                        <h1>Torneo {id_torneo}</h1>
                    </div>
                </div>
            </Layout>
        </LoginWrapper>
    )
};

export default TorneosDashboard;