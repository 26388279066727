import { loginContext } from "../../index.js";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";


const LoginWrapper = ({ children, allowed }) => {
    const navigate = useNavigate();
    const { isLogged, requestCompleted, user } = useContext(loginContext);
    useEffect(() => {
        if(requestCompleted && !isLogged) {
            navigate("/login");
            return;
        }
        if(requestCompleted && allowed && !allowed.includes(user.roles_sesione.nombre)) {
            navigate("/dashboard");
            return;
        }
    }, [requestCompleted])
    
    return (
        <>
            {children}
        </>
    );

};

export default LoginWrapper;