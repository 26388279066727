import LoginWrapper from "../../utils/authentication/LoginWrapper.js";
import { useContext, useState } from "react";
import Layout from "./Layout.js";


const Dashboard = () => {
    console.log("Hey!!")
    return (
    <LoginWrapper>
        <Layout>
            <h1>Hola!</h1>
        </Layout>
    </LoginWrapper>);
    }

export default Dashboard;