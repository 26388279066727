import React, { createContext } from 'react';
import TorneosDashboard from './components/administrative_actions/leader/TorneosDashboard/TorneosDashboard.js';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AuthForms from './components/auth_forms/AuthForms.js';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import Dashboard from './components/dashboard/Dashboard.js';
import Usuarios from './components/administrative_actions/administrator/Usuarios/Usuarios.js';
import Torneos from './components/administrative_actions/organizer/Torneos/Torneos.js';
import MisDeportistas from './components/administrative_actions/leader/Deportistas/MisDeportistas.js';
import { GoogleOAuthProvider } from '@react-oauth/google';
//núcleo
import "primereact/resources/primereact.min.css";
//iconos
import "primeicons/primeicons.css";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import LoginContextProvider from './utils/authentication/LoginContextProvider.js';
import ToastContextProvider from './utils/toast_provider/ToastContextProvider.js';
const loginContext = createContext();
const toastContext = createContext();
const router = createBrowserRouter([
  {
    path: "/login",
    element: <AuthForms reason={"login"} />
  },
  {
    path: "/signup",
    element: <AuthForms reason={"signup"} />
  },
  {
    path: "/dashboard",
    element: <Dashboard />

  },
  {
    path: "/dashboard/usuarios",
    element: <Usuarios />

  },
  {
    path: "/dashboard/torneos",
    element: <Torneos />
  },
  {
    path: "/dashboard/deportistas",
    element: <MisDeportistas />
  },
  {
    path: "/dashboard/torneos/:id_torneo",
    element: <TorneosDashboard />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ToastContextProvider>
    <GoogleOAuthProvider clientId="512800371591-67sffql8mchso4a6on5qml8vbpi4dc9j.apps.googleusercontent.com">
      <LoginContextProvider>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </LoginContextProvider>
    </GoogleOAuthProvider>
  </ToastContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { loginContext, toastContext };