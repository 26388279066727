import axiosInstance from '../requests/axios.instance.js';


const getUsuarios = async () => {
    const response = await axiosInstance.get('/api/data/usuarios');
    return response;
}


const getRoles = async () => {
    const response = await axiosInstance.get('/api/data/roles_sesiones');
    return response;
}
// usuario, contrasena, rol_id, fecha_sys, tipo_cuenta
const createUser = async (usuario, contrasena, rol_id, captcha_token) => {
    const response = await axiosInstance.post('/api/auth/signup', { usuario, contrasena, rol_id, captcha_token });
    if (response.data.success) return response;
    else throw response;
}
const getPaises = async () => {
    const response = await axiosInstance.get('/api/data/info_lugares');
    if (response.data.success) return response;
}

const getDepartamentos = async (paisId) => {
    const response = await axiosInstance.get('/api/data/info_lugares?id_pais=' + paisId);
    if (response.data.success) return response;
};

const getCiudades = async (paisId, departamentoId) => {
    const response = await axiosInstance.get('/api/data/info_lugares?id_pais=' + paisId + '&id_departamento=' + departamentoId);
    if (response.data.success) return response;
};

const deleteUsers = async (usuariosIds) => {
    const response = await axiosInstance.post('/api/admin/users/delete', { usuariosIds });
    return response;
};

const getTipoDeportes = async () => {
    const response = await axiosInstance.get('/api/data/deportes');
    if (response.data.success) return response;
}


const addTorneo = async (nombre, descripcion, precio_inscripcion, moneda, responsable, gps_ubicacion, estado, estado_inscripcion, foto, dates, ciudad_id, tipo_deporte_id, captcha_token, id_arbitro) => {
    const response = await axiosInstance.post('/api/organizer/torneos/create', { nombre, descripcion, precio_inscripcion, moneda, responsable, gps_ubicacion, estado, estado_inscripcion, foto, fecha_inicio: dates[0], fecha_fin: dates[1], ciudad_id, tipo_deporte_id, captcha_token, id_arbitro });
    if (response.data.success) return response;
    throw response;
}

const deleteTorneo = async (id_torneo) => {
    const response = await axiosInstance.post('/api/organizer/torneos/delete', { id_torneo });
    if (response.data.success) return response;
}
const editTorneo = async (toUpdate, id_torneo, captcha_token) => {
    const response = await axiosInstance.post('/api/organizer/torneos/edit', { id_torneo, toUpdate, captcha_token });
    if (response.data.success) return response;
    throw response;
};

const editUser = async (usuarioId, rolId, captcha_token) => {
    const response = await axiosInstance.post('/api/admin/users/edit', { usuarioId, rolId, captcha_token });
    if (response.data.success) return response;
    throw response;
}

const getDeportistas = async () => {
    const response = await axiosInstance.get('/api/data/deportistas');
    if (response.data.success) return response;
};

const addDeportista = async (nombre, apellido, documento, telefono, email, fecha_nacimiento, rh, fecha_sys, captcha_token) => {
    const response = await axiosInstance.post('/api/leader/deportistas/create', { nombre, apellido, documento, telefono, email, fecha_nacimiento, rh, fecha_sys, captcha_token });
    if (response.data.success) return response;
    throw response;
};



const getTorneos = async () => {
    const response = await axiosInstance.get('/api/data/torneos');
    if (response.data.success) return response;
};


const editDeportista = async (data, captcha_token, deportista_id) => {
    const response = await axiosInstance.post('/api/leader/deportistas/edit', { deportista_id: [deportista_id], captcha_token, toUpdate: data});
    if (response.data.success) return response;
    throw response;
};

const deleteDeportistas = async (deportistas_ids) => {
    const response = await axiosInstance.post('/api/leader/deportistas/delete', { deportistas_ids });
    if (response.data.success) return response;
    throw response;
};

const inscribirseTorneo = async (id_torneo) => {
    const response = await axiosInstance.post('/api/inscriptions/create', { id_torneo });
    if (response.data.success) return response;
    return response;
}

export { getUsuarios, getRoles, createUser, deleteUsers, editUser, getPaises, getDepartamentos, getCiudades, getTipoDeportes, addTorneo, getTorneos, deleteTorneo, editTorneo, getDeportistas, addDeportista, deleteDeportistas, editDeportista, inscribirseTorneo };