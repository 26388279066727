import axios from "axios";


const axiosInstance = axios.create({
    baseURL: "https://apiteamleague.comunisoft.com/",
    withCredentials: true,
    headers: {
        "Content-Type": "application/json"
    }
});

axiosInstance.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        window.PrimeToast({
            severity: "error",
            summary: "Error",
            detail: "Al parecer el servidor se encuentra caído"
        });
        return Promise.resolve({ data: {}, ...error });
    }
);
axiosInstance.interceptors.response.use(
    response => {
        if (response.data.message) {
            window.PrimeToast({
                severity: "success",
                summary: "Éxito",
                detail: response.data.message
            });
        };
        return response;
    },
    error => {
        console.log(error.response);
        if(error.response.status === 404) {
            window.PrimeToast({
                severity: "error",
                summary: "Error",
                detail: "No se encontró el recurso solicitado"
            });
        }
        else if (error.response && error.response.data.message) {
            window.PrimeToast({
                severity: "error",
                summary: "Error",
                detail: typeof error.response.data.message === "string" ? error.response.data.message : error.response.data.message.map((error, index) => <p style={{margin: "0"}} key={index}><b>{index+1}.</b> {error.msg}</p>)
            });
            
        } else {
            window.PrimeToast({
                severity: "error",
                summary: "Error",
                detail: "Al parecer el servidor se encuentra caído"
            });
        }
        return Promise.resolve({ data: {}, ...error });
    }
);

export default axiosInstance;