import { loginContext } from "../../index.js";
import { useEffect, useState } from "react";
import axiosInstance from "../requests/axios.instance.js";

const LoginContextProvider = ({ children }) => {
    const [requestCompleted, setRequestCompleted] = useState(false);
    const [user, setUser] = useState({});
    const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {
        const checkLogin = async () => {
            try {
                const response = await axiosInstance.get("/api/auth/check");
                console.log(response);
                if(response.data.success) {
                    setUser(response.data.user);
                    setIsLogged(true);
                }
            } catch(err) {
                console.log(err);
            } finally {
                setRequestCompleted(true);
            }
        }
        checkLogin();
    }, []);
    console.log("holaaa");
    return (
        <loginContext.Provider value={{ isLogged, user, requestCompleted }}>
        {children}
        </loginContext.Provider>
    );
    };

export default LoginContextProvider;